import { defineStore } from "pinia";
import type { LayoutNodeType } from "../interfaces/nodes.ts";
import { useUserStore } from "./userStore";
import { useNodeStore } from "./nodeStore";
import { useModalStore } from "./modalStore";
import * as vNG from "v-network-graph";
import { UserShip } from "../utils/userShip.ts";
import audioMixin from "../mixins/audioMixin.ts";
import {
    spawnAsteroid,
    generateStarPositions,
    maxZoomLevel
} from "../utils/latticeUtils.ts";
import vNGConfig from "../utils/vNGConfig.ts";
import { minZoomLevel } from "../utils/latticeUtils";
import gsap from "gsap";

const nStore = useNodeStore();
const uStore = useUserStore();
const mStore = useModalStore();

export const useVngStore = defineStore("vnetworkgraph", {
    state: () => ({
        vNGconfig: vNGConfig(),
        vNGComp: {} as vNG.Instance,
        graphElement: {} as HTMLElement,
        svgLatticeElement: {} as any,
        moon: {} as SVGImageElement,
        ship: new UserShip(),
        stars: [] as any[],
        boundaryWidth: 3000,
        boundaryHeight: 3000,
        selectedNodes: [],
        clickEventOccurred: false,
        centerNodeActive: false,
        disableRecenter: true,
        isBeingDragged: false,
        zoomLevel: 1,
        positionChangeThreshold: 0.25,
        velocityX: 0,
        velocityY: 0,
        lastTime: 0,
        touches: {},
        lastPanPosition: { x: 0, y: 0 }
    }),
    getters: {
        getVngComp: (state) => state.vNGComp,
        getGraphElement: (state) => state.graphElement,
        getSvgLatticeElement: (state) => state.svgLatticeElement,
        getShip: (state) => state.ship
    },
    actions: {
        setVngComp(
            vngComp: any,
            graphElement: HTMLElement,
            svgLatticeElement: any
        ) {
            this.vNGComp = vngComp;
            this.graphElement = graphElement;
            this.svgLatticeElement = svgLatticeElement;
        },
        eventHandlers() {
            return {
                //@ts-ignore
                "node:click": ({ node, event }) => {
                    const nodeLayoutDetails = nStore.layouts.nodes[node];
                    if (uStore.loggedIn) {
                        // User click
                        this.centerNode(true, node);
                        this.ship.startOrbitingTarget(
                            nodeLayoutDetails.x,
                            nodeLayoutDetails.y,
                            node
                        );
                        nStore.updateSelectedNodeID(parseInt(node));
                    } else {
                        // Guest click
                        mStore.toggleNodeModal(false);
                        this.ship.animateDustClouds();
                        this.ship
                            .startOrbitingTarget(
                                nodeLayoutDetails.x,
                                nodeLayoutDetails.y,
                                node
                            )
                            .then(() => {
                                nStore.updateSelectedNodeID(parseInt(node));
                                setTimeout(() => {
                                    mStore.toggleNode(true);
                                }, 500);
                                this.resetSelectedNodes();
                            });
                    }
                },
                "view:load": () => {
                    setTimeout(() => {
                        this.initSpaceship();
                        this.stars = generateStarPositions();
                        this.setupListeners();
                        this.centerPoint();
                    }, 50);
                },
                "view:click": (event: any) => {
                    this.clickEventOccurred = true;
                },
                "view:pan": (event: any) => {
                    if (!uStore.loggedIn) {
                        this.centerPoint();
                        return;
                    }
                    if (!event || !event.x || !event.y) {
                        this.vNGComp?.panTo(event);
                        return;
                    }
                    this.panWithBounds(event.x, event.y);
                    if (!this.centerNodeActive) {
                        this.clickEventOccurred = true;
                    }
                    this.lastPanPosition = { x: event.x, y: event.y };
                },
                "view:contextmenu": (event: Event) => {
                    this.preventDefault(event);
                },
                "node:contextmenu": (event: Event) => {
                    this.preventDefault(event);
                },
                "edge:contextmenu": (event: Event) => {
                    this.preventDefault(event);
                }
            };
        },
        panWithBounds(targetX: number, targetY: number) {
            if (!this.vNGComp) {
                console.warn("Skipping panWithBounds: vNGComp not available");
                return;
            } else if (!this.vNGComp?.panTo || !this.vNGComp?.getSizes) {
                console.error(
                    "vNGComp is available, but is missing functions? vNGComp:",
                    this.vNGComp
                );
                return;
            }

            const constrain = (value: number, min: number, max: number) =>
                Math.min(Math.max(value, min), max);
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const minX = -this.boundaryWidth / 2 + viewportWidth;
            const minY = -this.boundaryHeight / 2 + viewportHeight;
            const constrainedX = constrain(
                targetX,
                minX,
                this.boundaryHeight / 2
            );
            const constrainedY = constrain(
                targetY,
                minY,
                this.boundaryWidth / 2
            );
            this.vNGComp?.panTo({
                x: constrainedX,
                y: constrainedY
            });
        },
        centerPoint() {
            if (!this.vNGComp) {
                console.warn("Skipping centerPoint: vNGComp not available");
                return;
            }
            let targetX = 0;
            let targetY = 0;
            if (uStore.user && uStore.user.currentnode) {
                try {
                    targetX =
                        nStore.layouts.nodes[uStore.user.currentnode]?.x || 0;
                    targetY =
                        nStore.layouts.nodes[uStore.user.currentnode]?.y || 0;
                } catch (error) {
                    console.error("Error centering node:", error);
                }
            }
            const sizes = this.vNGComp?.getSizes();
            const width = sizes?.width || 0;
            const height = sizes?.height || 0;
            const zoomAdjustedX = targetX * this.zoomLevel;
            const zoomAdjustedY = targetY * this.zoomLevel;
            const newCenterX = width / 2 - zoomAdjustedX;
            const newCenterY = height / 2 - zoomAdjustedY;
            this.vNGComp?.panTo({ x: newCenterX, y: newCenterY });
        },
        setupListeners() {
            if (this.vNGComp) {
                this.graphElement.addEventListener(
                    "touchstart",
                    this.handleTouchStart,
                    { passive: true }
                );
                this.graphElement.addEventListener(
                    "touchmove",
                    this.handleTouchMove,
                    { passive: true }
                );
                this.graphElement.addEventListener(
                    "touchend",
                    this.handleTouchEnd,
                    {
                        passive: true
                    }
                );
                this.graphElement.addEventListener(
                    "mouseup",
                    this.handleMouseUp,
                    {
                        passive: true
                    }
                );
            } else {
                console.error(
                    "vNGComp reference not found or graph not initialized"
                );
            }
        },
        handleTouchStart(event: TouchEvent) {
            this.isBeingDragged = true;
            this.lastTime = performance.now();
            this.velocityX = 0;
            this.velocityY = 0;
            this.touches = Array.from(event.touches).map((touch) => ({
                identifier: touch.identifier,
                clientX: touch.clientX,
                clientY: touch.clientY
            }));
            let trueEventX = event.touches[0].clientX - this.lastPanPosition.x;
            let trueEventY = event.touches[0].clientY - this.lastPanPosition.y;
        },
        handleTouchMove(event: TouchEvent) {
            if (this.isBeingDragged && this.lastTime) {
                const currentTime = performance.now();
                const deltaTime = currentTime - this.lastTime;
                let totalDeltaX = 0;
                let totalDeltaY = 0;

                Array.from(event.touches).forEach((touch) => {
                    //@ts-ignore
                    const initialTouch = this.touches.find(
                        (t: any) => t.identifier === touch.identifier
                    );
                    if (initialTouch) {
                        const deltaX = touch.clientX - initialTouch.clientX;
                        const deltaY = touch.clientY - initialTouch.clientY;
                        totalDeltaX += deltaX;
                        totalDeltaY += deltaY;
                    }
                });

                const averageDeltaX = totalDeltaX / event.touches.length;
                const averageDeltaY = totalDeltaY / event.touches.length;
                this.velocityX = averageDeltaX / deltaTime;
                this.velocityY = averageDeltaY / deltaTime;
                this.lastTime = currentTime;
                this.touches = Array.from(event.touches).map((touch) => ({
                    identifier: touch.identifier,
                    clientX: touch.clientX,
                    clientY: touch.clientY
                }));
            }
        },
        handleTouchEnd(event: TouchEvent) {
            this.isBeingDragged = false;
            this.lastTime = 0;
            this.applyMomentum();
            this.disableRecenter = false;
        },
        handleMouseUp(event: MouseEvent) {
            this.disableRecenter = false;
        },
        applyMomentum() {
            if (!this.vNGComp) {
                console.error("vNGComp is not available");
                return;
            }

            const dampingFactor = 0.98;
            const cubicEaseIn = (t: any) => t * t * t;
            const maxSpeed = 3;

            const momentum = () => {
                this.velocityX *= dampingFactor;
                this.velocityY *= dampingFactor;

                this.velocityX = Math.min(
                    Math.max(this.velocityX, -maxSpeed),
                    maxSpeed
                );
                this.velocityY = Math.min(
                    Math.max(this.velocityY, -maxSpeed),
                    maxSpeed
                );

                const easedVelocityX = cubicEaseIn(this.velocityX);
                const easedVelocityY = cubicEaseIn(this.velocityY);
                this.vNGComp.panBy({
                    x: easedVelocityX,
                    y: easedVelocityY
                });
                if (
                    Math.abs(this.velocityX) > 0.25 ||
                    Math.abs(this.velocityY) > 0.25
                ) {
                    requestAnimationFrame(momentum);
                }
            };
            momentum();
        },
        async updateMinZoomLevel() {
            const newMinZoomLevel = minZoomLevel();
            if (this.zoomLevel != newMinZoomLevel) {
                await this.smoothZoom(newMinZoomLevel);
            }
            this.vNGconfig.view.minZoomLevel = newMinZoomLevel;
        },
        async smoothZoom(targetZoomLevel: number): Promise<void> {
            return new Promise((resolve) => {
                gsap.ticker.fps(60);
                gsap.to(this, {
                    duration: 1,
                    ease: "power3.inOut",
                    zoomLevel: targetZoomLevel,
                    onComplete: resolve
                });
            });
        },
        smoothPanTo(
            node: LayoutNodeType,
            x?: number,
            y?: number,
            retry: number = 0
        ): Promise<void> {
            return new Promise(async (resolve, reject) => {
                this.clickEventOccurred = false;
                this.centerNodeActive = true;
                let nodeX = node ? node.x : x ?? 0;
                let nodeY = node ? node.y : y ?? 0;
                let retry = 0;

                const sizes = this.vNGComp?.getSizes();
                let width = sizes?.width || 0;
                let height = sizes?.height || 0;
                const speed = 0.5;
                let zoomAdjustedX = nodeX * this.zoomLevel;
                let zoomAdjustedY = nodeY * this.zoomLevel;

                let isOutOfBoundary =
                    zoomAdjustedX > width || zoomAdjustedY > height;

                if (isOutOfBoundary) {
                    if (retry < 3) {
                        console.log(
                            "Node is out of boundary, attempting to refetch node coordinates."
                        );

                        await new Promise((resolve) => setTimeout(resolve, 10));

                        nodeX = node?.x || 0;
                        nodeY = node?.y || 0;
                        const updatedSizes = this.vNGComp?.getSizes();

                        zoomAdjustedX = nodeX * this.zoomLevel;
                        zoomAdjustedY = nodeY * this.zoomLevel;
                        const updatedWidth = updatedSizes?.width || 0;
                        const updatedHeight = updatedSizes?.height || 0;

                        const isStillOutOfBoundary =
                            zoomAdjustedX > updatedWidth ||
                            zoomAdjustedY > updatedHeight;

                        if (isStillOutOfBoundary) {
                            return this.smoothPanTo(
                                node,
                                undefined,
                                undefined,
                                retry + 1
                            );
                        } else {
                            isOutOfBoundary = false;
                            width = updatedWidth;
                            height = updatedHeight;
                        }
                    } else {
                        console.warn(
                            "Node is out of boundary, unable to refetch node coordinates."
                        );
                        throw new Error("Node is out of boundary.");
                    }
                }

                const targetX = width / 2 - zoomAdjustedX;
                const targetY = height / 2 - zoomAdjustedY;

                const currentPan = this.vNGComp?.getPan();
                const startX = currentPan?.x || 0;
                const startY = currentPan?.y || 0;

                const isAlreadyCentered =
                    Math.abs(startX - targetX) < this.positionChangeThreshold &&
                    Math.abs(startY - targetY) < this.positionChangeThreshold;
                if (isAlreadyCentered) {
                    this.centerNodeActive = false;
                    this.disableRecenter = true;
                    resolve();
                    return;
                }

                const deltaX = targetX - startX;
                const deltaY = targetY - startY;
                const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
                const totalDuration = distance / speed;

                try {
                    audioMixin.methods?.playCenterSound(totalDuration);
                } catch (error) {
                    console.warn("Error playing center sound:", error);
                }

                const tween = gsap.to(currentPan, {
                    x: targetX,
                    y: targetY,
                    duration: totalDuration / 1000,
                    ease: "power1.inOut",
                    onUpdate: () => {
                        if (this.clickEventOccurred || this.isBeingDragged) {
                            tween.kill();
                            this.centerNodeActive = false;
                            audioMixin.methods?.stopCenterSound();
                            return;
                        }
                        this.vNGComp?.panTo({
                            x: currentPan.x,
                            y: currentPan.y
                        });
                    },
                    onComplete: () => {
                        this.centerNodeActive = false;
                        this.disableRecenter = true;
                        resolve();
                    },
                    onInterrupt: () => {
                        this.centerNodeActive = false;
                        audioMixin.methods?.stopCenterSound();
                    }
                });
                this.disableRecenter = true;
            });
        },
        async centerNode(
            toggleModal?: boolean,
            nodeId?: number
        ): Promise<void> {
            try {
                if (nodeId === undefined) {
                    if (uStore.user && uStore.user.currentnode) {
                        nodeId = uStore.user.currentnode;
                    } else if (!uStore.loggedIn) {
                        nodeId = -5;
                    } else {
                        throw new Error("nodeId is undefined");
                    }
                }

                const node = nStore.layouts.nodes[nodeId];
                if (!node) {
                    throw new Error(`Node with ID ${nodeId} not found`);
                }

                await this.smoothPanTo(node);
                if (toggleModal) {
                    this.smoothZoom(maxZoomLevel());
                    mStore.toggleNodeModal();
                }
            } catch (error) {
                console.error("Error centering node:", error);
                throw error;
            }
        },
        preventDefault(event: any) {
            if (
                event &&
                event.event &&
                typeof event.event.preventDefault === "function"
            ) {
                event.event.preventDefault();
            } else if (event && typeof event.preventDefault === "function") {
                event.preventDefault();
            } else {
                console.warn(
                    "Unable to prevent default: event or preventDefault method not available"
                );
            }
        },
        resetSelectedNodes() {
            this.selectedNodes = [];
        },
        async initSpaceship() {
            const ship = await this.ship.getShip();
            spawnAsteroid(this.svgLatticeElement, ship);
            if (uStore.loggedIn && uStore.user) {
                this.ship.startOrbitingTarget(
                    nStore.layouts.nodes[uStore.user.currentnode].x,
                    nStore.layouts.nodes[uStore.user.currentnode]?.y,
                    uStore.user.currentnode
                );
            } else {
                this.createMoon();
            }
        },
        async createMoon() {
            const moonRadius = 100;

            const moonGroup = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "g"
            );
            moonGroup.setAttribute("id", "moon");
            moonGroup.setAttribute("class", "moon");

            const moonStroke = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "circle"
            );
            moonStroke.setAttribute("cx", "0");
            moonStroke.setAttribute("cy", "0");
            moonStroke.setAttribute("r", "95");
            moonStroke.setAttribute("stroke", "#ffffff");
            moonStroke.setAttribute("stroke-width", "8");
            moonStroke.setAttribute("fill", "none");

            const moon = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "image"
            );
            moon.setAttribute("x", "-100");
            moon.setAttribute("y", "-100");
            moon.setAttribute("width", "200");
            moon.setAttribute("height", "200");
            moon.setAttribute("href", `/images/moon.png`);

            const moonShadow = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "circle"
            );
            moonShadow.setAttribute("cx", `-${moonRadius * 0.15}`);
            moonShadow.setAttribute("cy", `${moonRadius * 0.15}`);
            moonShadow.setAttribute("r", "110");
            moonShadow.setAttribute("fill", "url(#shadow-gradient)");

            moonGroup.appendChild(moonShadow);
            moonGroup.appendChild(moon);
            moonGroup.appendChild(moonStroke);

            try {
                this.svgLatticeElement.insertBefore(
                    moonGroup,
                    await this.ship.getShip()
                );
            } catch (error) {
                console.warn("Error adding moon to svg:", error);
                console.log("Hot reload error, refreshing page...");
                location.reload();
            }

            gsap.set(moonGroup, { x: 0, y: this.ship.y + 100 });
        }
    }
});
