import * as vNG from "v-network-graph";
import { minZoomLevel, maxZoomLevel } from "./latticeUtils";
import { ForceLayout } from "v-network-graph/lib/force-layout";

export default () => {
    const forceSimulation = new ForceLayout({
        createSimulation: (d3, nodes, edges) => {
            const forceLink = d3
                .forceLink(edges)
                .id((d) => d.id)
                .strength(0.5)
                .distance(15);

            const forceManyBody = d3
                .forceManyBody()
                .strength(-3250)
                .distanceMin(0)
                .distanceMax(500);

            // Initialize the simulation with nodes and edges
            const simulation = d3
                .forceSimulation(nodes)
                .force("edge", forceLink)
                .force("manyBody", forceManyBody)
                .velocityDecay(0.075);

            simulation.tick(200);
            return simulation;
        }
    });

    return vNG.defineConfigs({
        view: {
            scalingObjects: true,
            zoomEnabled: true,
            doubleClickZoomEnabled: false,
            maxZoomLevel: maxZoomLevel(), // Zoom in
            minZoomLevel: minZoomLevel(), // Zoom out
            layoutHandler: forceSimulation
        },
        node: {
            draggable: false,
            selectable: true,
            label: {
                color: (node) => node.labelcolor,
                margin: 18,
                directionAutoAdjustment: false,
                fontSize: 16,
                fontFamily: "Work Sans",
                background: {
                    visible: false
                }
            },
            normal: {
                type: "circle",
                radius: (node) => node.size
            },
            hover: {
                type: "circle",
                radius: (node) => node.size
            },
            focusring: {
                visible: false,
                width: 4,
                padding: 12,
                color: "#ffc409"
            }
        },
        edge: {
            margin: 10,
            selectable: false,
            summarize: false,
            hover: {
                width: (edge) => edge.edgewidth,
                color: (edge) => edge.color,
                dasharray: (edge) => edge.dasharray,
                animate: (edge) => edge.animated,
                animationSpeed: 4,
                linecap: "round"
            },
            normal: {
                width: (edge) => edge.edgewidth,
                color: (edge) => edge.color,
                dasharray: (edge) => edge.dasharray,
                animate: (edge) => edge.animated,
                animationSpeed: 4,
                linecap: "round"
            }
        }
    });
};
